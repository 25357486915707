import React, { useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Button, Modal, Form } from 'react-bootstrap';

const Page5 = () => {
  const navigate = useNavigate();
  const [agentTypes, setAgentTypes] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [mode, setMode] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const baseUrl = 'https://automate-dev-backend-nsxedyyoha-uc.a.run.app/api';
  const idToken = localStorage.getItem('token');

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${baseUrl}/agenttype`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      const data = await response.json();
      setAgentTypes(data);
    } catch (error) {
      console.error('Error fetching agent types:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAction = (action, item = {}) => {
    setMode(action);
    setEditingItem(action === 'create' ? getEmptyItem() : item);
    setShowDialog(true);
  };

  const handleAgentDefinition = (agentId) => {
    navigate(`/agent-definition/${agentId}`);
  };

  const getEmptyItem = () => ({
    id: '',
    agent_filter: '',
    active: 'true'
  });

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const url = `${baseUrl}/agenttype${mode !== 'create' ? `/${editingItem.id}` : ''}`;
      const method = mode === 'create' ? 'POST' : mode === 'update' ? 'PUT' : 'DELETE';
      
      await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: method !== 'DELETE' ? JSON.stringify(editingItem) : undefined,
      });

      await fetchData();
      setShowDialog(false);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setEditingItem(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <div className="container">
      <Link to="/dashboard" className="d-flex align-items-center mb-4 text-decoration-none">
        <BsArrowLeft className="me-2" />
        <span>Back to Dashboard</span>
      </Link>

      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4 className="mb-0">Agent Types</h4>
          <Button 
            variant="primary"
            onClick={() => handleAction('create')}
            disabled={isLoading}
          >
            Add New Agent Type
          </Button>
        </div>
        <div className="card-body">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Agent Filter</th>
                <th>Active</th>
                <th style={{ width: '280px' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {agentTypes.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.agent_filter}</td>
                  <td>
                    <Form.Check 
                      type="switch"
                      checked={item.active === 'true'}
                      disabled
                    />
                  </td>
                  <td>
                    <div className="d-flex gap-2">
                      <Button 
                        variant="primary"
                        size="sm"
                        onClick={() => handleAction('update', item)}
                        disabled={isLoading}
                      >
                        Edit
                      </Button>
                      <Button 
                        variant="info"
                        size="sm"
                        onClick={() => handleAgentDefinition(item.id)}
                        disabled={isLoading}
                      >
                        Agent Definition
                      </Button>
                      <Button 
                        variant="danger"
                        size="sm"
                        onClick={() => handleAction('delete', item)}
                        disabled={isLoading}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      <Modal show={showDialog} onHide={() => setShowDialog(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {mode.charAt(0).toUpperCase() + mode.slice(1)} Agent Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingItem && (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Agent Filter</Form.Label>
                <Form.Control
                  type="text"
                  value={editingItem.agent_filter}
                  onChange={(e) => handleInputChange('agent_filter', e.target.value)}
                  disabled={mode === 'delete'}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check
                  type="switch"
                  label="Active"
                  checked={editingItem.active === 'true'}
                  onChange={(e) => handleInputChange('active', e.target.checked.toString())}
                  disabled={mode === 'delete'}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={() => setShowDialog(false)}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button 
            variant={mode === 'delete' ? 'danger' : 'primary'}
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : mode.charAt(0).toUpperCase() + mode.slice(1)}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Page5;