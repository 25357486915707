import React, { useEffect, useState } from 'react';
import { Table, Button, Modal ,Switch } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Orgspage = () => {
    const [orgsData, setOrgsData] = useState([]);
    const [editingOrg, setEditingOrg] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [mode, setMode] = useState('');

    const baseurl = process.env.REACT_APP_API_URL;
    const idToken = localStorage.getItem('token');

    useEffect(() => {
        fetchOrgsData();
    }, []);

    const fetchOrgsData = async () => {
        try {
            const response = await fetch(baseurl + `admin/org`, {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            });
            const jsonData = await response.json();
            setOrgsData(jsonData);
        } catch (error) {
            console.error('Error fetching orgs data:', error);
        }
    };

    const handleEditOrg = (org, mode) => {
        setEditingOrg(org);
        setMode(mode);
        setShowModal(true);
    };

    const handleSaveOrg = async (org) => {
        // Implement the logic to create, update or delete the org based on the mode
        // For example:
        // if (mode === 'create') { /* API call to create org */ }
        // else if (mode === 'update') { /* API call to update org */ }
        // else if (mode === 'delete') { /* API call to delete org */ }

        setShowModal(false);
        setEditingOrg({});
        await fetchOrgsData();
    };
    
    const handleToggle = async (orgId,askme ,active) => {
        // Logic to handle the toggle switch change
        // This should include updating the state locally and making an API call to persist the change

        try {
            const response = await fetch(baseurl + `admin/org/${orgId}`, {
                method: 'PUT', // Assuming the method to update is PUT
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({"askme":askme,"active":active}),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }       
            await fetchOrgsData();
        } catch (error) {
            console.error('Error fetching orgs data:', error);
        }
    };

   const handleDeleteBusinessOverview = async (org) => {
    try {
        const response = await fetch(
            baseurl + `admin/deleteOrgInfo?tenant=${org.org_name}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${idToken}`,
                }
            }
        );
        
        if (response.status === 200) {
            alert('Business overview deleted successfully');
        } else {
            throw new Error('Network response was not ok');
        }
        
        await fetchOrgsData();
    } catch (error) {
        console.error('Error deleting business overview:', error);
    }
};


    return (
        <div className="container">
            <Link to="/dashboard">Back to Dashboard</Link>
            <h1>Organizations</h1>
           
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Organization Name</th>
                        <th>Email</th>
                        <th>Tenant</th>
                        <th>Active</th>
                        <th>AskMe</th>
                        <th>Delete Business Overview</th> 
                    </tr>
                </thead>
                <tbody>
                    {orgsData.map(org => (
                        <tr key={org.id}>
                            <td>{org.id}</td>
                            <td>{org.org_name}</td>
                            <td>{org.org_email}</td>
                            <td>{org.org_tenant}</td>
                            <td>
                                <label className="switch">
                                    <input 
                                        type="checkbox" 
                                        checked={org.active} 
                                        onChange={(e) => handleToggle(org.id , org.askme , +e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>
                            </td>
                            <td>
                                <label className="switch">
                                    <input 
                                        type="checkbox" 
                                        checked={org.askme} 
                                        onChange={(e) => handleToggle(org.id, +e.target.checked ,org.active )} />
                                    <span className="slider round"></span>
                                </label>
                            </td>
                            <td>
                                <Button
                                    variant="danger"
                                    onClick={() =>
                                        handleDeleteBusinessOverview(org)
                                    }
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{mode === 'create' ? 'Create Organization' : 'Edit Organization'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Form fields for editing or creating an organization */}
                    {/* For example: */}
                    {/* <input value={editingOrg.org_name} onChange={(e) => setEditingOrg({...editingOrg, org_name: e.target.value})} /> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="primary" onClick={() => handleSaveOrg(editingOrg)}>{mode === 'create' ? 'Create' : 'Save'}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Orgspage;
