import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, Table, Spinner, Button, Modal, Form } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';

const TaskDefinitionPage = () => {
  const { agentId } = useParams();
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('');
  const [editingTask, setEditingTask] = useState(null);

  const baseUrl = 'https://automate-dev-backend-nsxedyyoha-uc.a.run.app/api';
  const idToken = localStorage.getItem('token');

  const fetchTasks = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch(`${baseUrl}/crewtask?crewAgentId=${agentId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch tasks');
      }
      
      const data = await response.json();
      setTasks(data);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching tasks:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [agentId]);

  const handleAction = (mode, task = null) => {
    setModalMode(mode);
    setEditingTask(mode === 'create' ? {
      description: '',
      expected_output: '',
      crewAgentId: agentId
    } : task);
    setShowModal(true);
  };

  const handleInputChange = (field, value) => {
    setEditingTask(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const url = modalMode === 'create' 
        ? `${baseUrl}/crewtask`
        : `${baseUrl}/crewtask/${editingTask.id}`;
      
      const method = modalMode === 'create' 
        ? 'POST' 
        : modalMode === 'edit' 
          ? 'PUT' 
          : 'DELETE';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: method !== 'DELETE' ? JSON.stringify(editingTask) : undefined,
      });

      if (!response.ok) {
        throw new Error(`Failed to ${modalMode} task`);
      }

      await fetchTasks();
      setShowModal(false);
    } catch (err) {
      setError(err.message);
      console.error(`Error ${modalMode}ing task:`, err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <Link to={`/page5`} className="d-flex align-items-center mb-4 text-decoration-none">
        <BsArrowLeft className="me-2" />
        <span>Back to Agent Types</span>
      </Link>

      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h4 className="mb-0">Task Definition - Agent ID: {agentId}</h4>
          <Button 
            variant="primary"
            onClick={() => handleAction('create')}
            disabled={isLoading}
          >
            Create New Task
          </Button>
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <div className="text-center p-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : error ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : (
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Description</th>
                  <th>Expected Output</th>
                  <th style={{ width: '220px' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map((task) => (
                  <tr key={task.id}>
                    <td>{task.id}</td>
                    <td>
                      <div style={{ maxWidth: '400px', whiteSpace: 'pre-wrap' }}>
                        {task.description}
                      </div>
                    </td>
                    <td>
                      <div style={{ maxWidth: '400px', whiteSpace: 'pre-wrap' }}>
                        {task.expected_output}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex gap-2">
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => handleAction('edit', task)}
                          disabled={isLoading}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleAction('delete', task)}
                          disabled={isLoading}
                        >
                          Delete
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {modalMode.charAt(0).toUpperCase() + modalMode.slice(1)} Task
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingTask && modalMode !== 'delete' ? (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={editingTask.description}
                  onChange={(e) => handleInputChange('description', e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Expected Output</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={editingTask.expected_output}
                  onChange={(e) => handleInputChange('expected_output', e.target.value)}
                />
              </Form.Group>
            </Form>
          ) : (
            <p>Are you sure you want to delete this task?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={() => setShowModal(false)}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button 
            variant={modalMode === 'delete' ? 'danger' : 'primary'}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : modalMode === 'delete' ? 'Delete' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TaskDefinitionPage;