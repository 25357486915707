import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Tab, Nav, Table, Button, Modal, Form } from 'react-bootstrap';
import { BsArrowLeft, BsToggleOn, BsToggleOff } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Page2 = () => {
  const url = process.env.REACT_APP_API_URL;
  const [showMediaPopup, setShowMediaPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selected, setSelected] = useState([]);
  const [table3Data, setTable3Data] = useState([]);
  const [table4Data, setTable4Data] = useState([]);
  const [table5Data, setTable5Data] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const idToken = localStorage.getItem('token');
  const [mode, setModeValue] = useState('');
  const [editingItem, setEditingItem] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await fetch(url + 'admin/org/', {
      headers: {
        Authorization: `Bearer ${idToken}`,
      }
    });
    const jsonData = await response.json();
    setOptions(jsonData);
  };

  const handleInputChange = (query) => {
    const filteredOptions = options.filter((option) =>
      option.org_name.toLowerCase().includes(query.toLowerCase())
    );
    setOptions(filteredOptions);
  };

  const handleOptionSelected = (selected) => {
    setSelectedOption(selected[0]);
  };

  const fetchTemplate = async () => {
    if (!selectedOption) return;
    
    const response3 = await fetch(url + `admin/getAllTemplate?tenant=${selectedOption.org_name}`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    const jsonData3 = await response3.json();
    setTable3Data(jsonData3);
  };

  const handleActiveToggle = async (id, currentActive) => {
    try {
      setLoading(true);
      const response = await fetch(url + `admin/userllmmodel/updateactive/${id}?tenant=${selectedOption.org_name}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          active: !currentActive
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update active status');
      }

      setTable3Data(table3Data.map(item => 
        item.id === id ? { ...item, active: !currentActive } : item
      ));

    } catch (error) {
      alert("Error updating active status: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const getActiveToggle = (item) => {
    return (
      <div className="flex items-center justify-between">
        <Button
          variant="link"
          onClick={() => handleActiveToggle(item.id, item.active)}
          disabled={loading}
          className="p-0 d-flex align-items-center"
        >
          {item.active ? (
            <BsToggleOn size={24} className="text-green-500" />
          ) : (
            <BsToggleOff size={24} className="text-gray-500" />
          )}
        </Button>
        <span className="ml-2">{item.active ? 'Active' : 'Inactive'}</span>
      </div>
    );
  };

  const statusButton = (id, active, status, org_name) => {
    return (
      <Button 
        variant="primary" 
        disabled={!active}
        onClick={() => handleStatusClick(id, org_name)}
      >
        Status
      </Button>
    );
  };

  const renderButton = (id, active, status, org_name) => {
    let buttonVariant = '';
    let isButtonDisabled = !active;
    let buttonText = status;

    if (status === 'SCHEDULED') {
      buttonVariant = 'primary';
      buttonText = "TRIGGER";
    } else if (active && status === 'DONE') {
      buttonVariant = 'success';
      isButtonDisabled = true;
    } else if (active && status === 'NEW') {
      buttonVariant = 'danger';
      isButtonDisabled = true;
    } else {
      buttonVariant = 'secondary';
      isButtonDisabled = true;
    }

    return (
      <Button 
        variant={buttonVariant} 
        disabled={isButtonDisabled}
        onClick={() => handleButtonClick(id, org_name)}
      >
        {buttonText}
      </Button>
    );
  };

  const savePrompt = (item, table5Data) => {
    return (
      <Button 
        variant="primary"
        onClick={() => savePromptClick(item, table5Data)}
      >
        Save Prompt
      </Button>
    );
  };

  const savePromptClick = async (item, table5Data) => {
    const data = {
      provider: table5Data[0].LLM,
      input: table5Data[0].input,
      prompt: table5Data[0].prompt,
    };
    
    try {
      const response = await fetch(url + `admin/userllmmodel/${item}?tenant=${selectedOption.org_name}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) throw new Error('Request failed');
      alert("Job Triggered");
    } catch (error) {
      alert("Error: " + error.message);
    }
  };

  const validateFile = (id, features_column, target_column, gcs_source) => {
    return (
      <Button 
        variant="primary"
        onClick={() => handleValidateClick(id, features_column, target_column, gcs_source)}
      >
        Validate File
      </Button>
    );
  };

  const handleValidateClick = async (id, features_column, target_column, gcs_source) => {
    const data = {
      source_column: JSON.parse(features_column),
      target_column: target_column,
      gcs_source: gcs_source
    };

    try {
      const response = await fetch(url + `admin/usercustommodel/validateFile?tenant=${selectedOption.org_name}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify(data),
      });
      const jsonData = await response.json();
      alert(JSON.stringify(jsonData));
    } catch (error) {
      alert("Error: " + error.message);
    }
  };

  const handlePromptChange = (index, subIndex, event) => {
    const newData = [...table5Data];
    newData[index].prompt[subIndex].prompt = event.target.value;
    setTable5Data(newData);
  };

  const handleStatusClick = async (id, org_name) => {
    try {
      const [response4, response5] = await Promise.all([
        fetch(url + `admin/usercustommodel/templateId/${id}?tenant=${selectedOption.org_name}`, {
          headers: { Authorization: `Bearer ${idToken}` },
        }),
        fetch(url + `admin/userllmmodel/templateId/${id}?tenant=${selectedOption.org_name}`, {
          headers: { Authorization: `Bearer ${idToken}` },
        })
      ]);

      const [jsonData4, jsonData5] = await Promise.all([
        response4.json(),
        response5.json()
      ]);

      setTable4Data(jsonData4);
      setTable5Data(jsonData5);
      setShowMediaPopup(true);
    } catch (error) {
      alert("Error fetching status: " + error.message);
    }
  };

  const handleButtonClick = async (id, org_name) => {
    try {
      const response = await fetch(url + 'admin/topic/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          templateId: id,
          orgName: org_name
        }),
      });

      if (!response.ok) throw new Error('Request failed');
      alert("Job Triggered");
    } catch (error) {
      alert("Error: " + error.message);
    }
  };

  return (
    <div className="container">
      <Link to="/dashboard" className="flex items-center gap-2 mb-4">
        <BsArrowLeft /> Back
      </Link>
      
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="form-group">
            <div className="input-group">
              <Typeahead
                id="autocomplete-example"
                labelKey="org_name"
                options={options}
                placeholder="Enter Organization ..."
                onChange={handleOptionSelected}
                onInputChange={handleInputChange}
                selected={selectedOption ? [selectedOption] : []}
              />
              <div className="input-group-append">
                <button 
                  className="btn btn-primary" 
                  type="button" 
                  onClick={fetchTemplate}
                  disabled={!selectedOption || loading}
                >
                  Get Organizations
                </button>
              </div>
            </div>
          </div>
        </div>

        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>Trigger</th>
              <th>Meaning</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><Button variant="danger" disabled>DRAFT</Button></td>
              <td>New WorkFlow</td>
            </tr>
            <tr>
              <td><Button variant="success" disabled>DONE</Button></td>
              <td>WorkFlow Completed. All models within the workflow has executed successfully (disabled)</td>
            </tr>
            <tr>
              <td><Button variant="primary">TRIGGERED</Button></td>
              <td>WorkFlow can be triggered to execute models within it</td>
            </tr>
            <tr>
              <td><Button variant="secondary" disabled>DONE/NEW/TRIGGERED</Button></td>
              <td>WorkFlow deleted/Inactive (disabled)</td>
            </tr>
          </tbody>
        </Table>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Template Name</th>
              <th>Status</th>
              <th>Active</th>
              <th>Trigger</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {table3Data.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.template_name}</td>
                <td>{item.status}</td>
                <td>{getActiveToggle(item)}</td>
                <td>{renderButton(item.id, item.active, item.status, selectedOption?.org_name)}</td>
                <td>{statusButton(item.id, item.active, item.status, selectedOption?.org_name)}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Modal show={showMediaPopup} onHide={() => setShowMediaPopup(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Model State</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover className="mb-4">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Meaning</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>NEW</td>
                  <td>New Model (Model not triggered)</td>
                </tr>
                <tr>
                  <td>RUNNING</td>
                  <td>Model is in a running state</td>
                </tr>
                <tr>
                  <td>DONE</td>
                  <td>Model Completed</td>
                </tr>
              </tbody>
            </Table>

            <h5>Custom Model</h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Model ID</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {table4Data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.model_name}</td>
                    <td>{item.status}</td>
                    <td>{validateFile(item.id, item.features_column, item.target_column, item.gcs_source)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <h5 className="mt-4">LLM Model</h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Model ID</th>
                  <th>Status</th>
                  <th>Prompt</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {table5Data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.model_name}</td>
                    <td>{item.status}</td>
                    <td>
                      <ul className="list-none p-0">
                        {item.prompt.map((subItem, subIndex) => (
                          <li key={subItem.id} className="mb-2">
                            <div className="font-bold mb-1">Prompt {subItem.id}</div>
                            <textarea
                              className="w-full p-2 border rounded"
                              rows="3"
                              defaultValue={subItem.prompt}
                              onChange={(event) => handlePromptChange(index, subIndex, event)}
                            />
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>{savePrompt(item.id, table5Data)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowMediaPopup(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Page2;