import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import { Card, Table, Spinner, Button, Modal, Form } from 'react-bootstrap';

const AgentDefinitionPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [crewAgents, setCrewAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('');
  const [editingAgent, setEditingAgent] = useState(null);

  const baseUrl = 'https://automate-dev-backend-nsxedyyoha-uc.a.run.app/api';
  const idToken = localStorage.getItem('token');

  const fetchCrewAgents = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch(`${baseUrl}/crewagent?crewTypeId=${id}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch crew agents');
      }
      
      const data = await response.json();
      setCrewAgents(data);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching crew agents:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCrewAgents();
  }, [id]);

  const handleAction = (mode, agent = null) => {
    setModalMode(mode);
    setEditingAgent(mode === 'create' ? {
      name: '',
      role: '',
      goal: '',
      backstory: '',
      crewTypeId: id
    } : agent);
    setShowModal(true);
  };

  const handleInputChange = (field, value) => {
    setEditingAgent(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const url = modalMode === 'create' 
        ? `${baseUrl}/crewagent`
        : `${baseUrl}/crewagent/${editingAgent.id}`;
      
      const method = modalMode === 'create' 
        ? 'POST' 
        : modalMode === 'edit' 
          ? 'PUT' 
          : 'DELETE';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: method !== 'DELETE' ? JSON.stringify(editingAgent) : undefined,
      });

      if (!response.ok) {
        throw new Error(`Failed to ${modalMode} crew agent`);
      }

      await fetchCrewAgents();
      setShowModal(false);
    } catch (err) {
      setError(err.message);
      console.error(`Error ${modalMode}ing crew agent:`, err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTaskClick = (agentId) => {
    navigate(`/task-definition/${agentId}`);
  };

  return (
    <div className="container">
     <Link to={`/page5`} className="d-flex align-items-center mb-4 text-decoration-none">
  <BsArrowLeft className="me-2" />
  <span>Back to Agent Types</span>
</Link>


      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h4 className="mb-0">Agent Definition - ID: {id}</h4>
          <Button 
            variant="primary"
            onClick={() => handleAction('create')}
            disabled={isLoading}
          >
            Create New Agent
          </Button>
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <div className="text-center p-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : error ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : (
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Goal</th>
                  <th>Backstory</th>
                  <th style={{ width: '300px' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {crewAgents.map((agent) => (
                  <tr key={agent.id}>
                    <td>{agent.id}</td>
                    <td>
                      <div className="text-capitalize">{agent.name}</div>
                    </td>
                    <td>{agent.role}</td>
                    <td>
                      <div style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}>
                        {agent.goal}
                      </div>
                    </td>
                    <td>
                      <div style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}>
                        {agent.backstory}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex gap-2">
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => handleAction('edit', agent)}
                          disabled={isLoading}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleAction('delete', agent)}
                          disabled={isLoading}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => handleTaskClick(agent.id)}
                          disabled={isLoading}
                        >
                          Task
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalMode.charAt(0).toUpperCase() + modalMode.slice(1)} Agent
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingAgent && modalMode !== 'delete' ? (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={editingAgent.name}
                  onChange={(e) => handleInputChange('name', e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  type="text"
                  value={editingAgent.role}
                  onChange={(e) => handleInputChange('role', e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Goal</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={editingAgent.goal}
                  onChange={(e) => handleInputChange('goal', e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Backstory</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={editingAgent.backstory}
                  onChange={(e) => handleInputChange('backstory', e.target.value)}
                />
              </Form.Group>
            </Form>
          ) : (
            <p>Are you sure you want to delete this agent?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={() => setShowModal(false)}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button 
            variant={modalMode === 'delete' ? 'danger' : 'primary'}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : modalMode === 'delete' ? 'Delete' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AgentDefinitionPage;