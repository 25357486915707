import React from 'react';
import Dashboard from './Dashboard';
import LoginPage from './LoginPage';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5 from './Page5';
import AgentDefinitionPage from './AgentDefinitionPage';
import TaskDefinitionPage from './TaskDefinitionPage';
import Orgspage from './Orgspage';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { AppConstant } from './helper/constant';
function App() {
  return (
    <BrowserRouter basename='/'>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path={`/${AppConstant.PAGES.LOGIN}`} element={<LoginPage />} />
        <Route path={`/${AppConstant.PAGES.DASHBOARD}`} element={<Dashboard />} />
        <Route path="/page1" element={<Page1 />} />
        <Route path="/Orgspage" element={<Orgspage />} />
        <Route path="/page2" element={<Page2 />} />
        <Route path="/page3" element={<Page3 />} />
        <Route path="/page4" element={<Page4 />} />
        <Route path="/page5" element={<Page5 />} />
        <Route path="/agent-definition/:id" element={<AgentDefinitionPage />} />
        <Route path="/task-definition/:agentId" element={<TaskDefinitionPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
