import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <Container className="py-5">
      <h2 className="text-center mb-5" style={{ fontWeight: 'bold', color: '#333' }}>
        Automate AI Dashboard
      </h2>

      <Row xs={1} md={2} lg={3} className="g-4">
        {/* Card 1 */}
        <Col>
          <Card className="shadow-sm" border="primary">
            <Card.Header className="bg-primary text-white">
              <Card.Title>Environmental Variables</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>Change all common environmental variables in Automate-AI</Card.Text>
            </Card.Body>
            <Card.Footer className="bg-light text-center">
              <Link to="/page1">
                <Button variant="primary">Click</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        {/* Card 2 */}
        <Col>
          <Card className="shadow-sm" border="primary">
            <Card.Header className="bg-primary text-white">
              <Card.Title>Organization Variables</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>Change each organization’s environmental variables in Automate-AI</Card.Text>
            </Card.Body>
            <Card.Footer className="bg-light text-center">
              <Link to="/orgspage">
                <Button variant="primary">Click</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        {/* Card 3 */}
        <Col>
          <Card className="shadow-sm" border="primary">
            <Card.Header className="bg-primary text-white">
              <Card.Title>Create Model</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>Create a new model</Card.Text>
            </Card.Body>
            <Card.Footer className="bg-light text-center">
              <Link to="/page3">
                <Button variant="primary">Click</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        {/* Card 4 */}
        <Col>
          <Card className="shadow-sm" border="primary">
            <Card.Header className="bg-primary text-white">
              <Card.Title>Execute Workflow</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>Execute workflows for each organization</Card.Text>
            </Card.Body>
            <Card.Footer className="bg-light text-center">
              <Link to="/page2">
                <Button variant="primary">Click</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        {/* Card 5 */}
        <Col>
          <Card className="shadow-sm" border="primary">
            <Card.Header className="bg-primary text-white">
              <Card.Title>LLM</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>Create Large Language Models</Card.Text>
            </Card.Body>
            <Card.Footer className="bg-light text-center">
              <Link to="/page4">
                <Button variant="primary">Click</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        {/* New Card for Page 5 */}
        <Col>
          <Card className="shadow-sm" border="primary">
            <Card.Header className="bg-primary text-white">
              <Card.Title>Agents</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>Create and Update new Agents </Card.Text>
            </Card.Body>
            <Card.Footer className="bg-light text-center">
              <Link to="/page5">
                <Button variant="primary">Click</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
